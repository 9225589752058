<template>
  <div class="w-full h-full">
    <img src="~@/assets/offering/offering-new.jpg" alt="offering">
  </div>
</template>

<script>
export default {
  name: 'OfferingVa'
}
</script>

<style scoped>

</style>
